<template>
       <div class="content">
         <div class="contents">
             <p class="title"><span>
                 {{$t('language.WestinHotels')}}
                 <!-- 威斯汀酒店群 -->
             </span><span>
                    <img v-for="(items) in 5" :key="items" src="../../assets/image/lALPDg7mRkdgwScSFA_20_18.png" alt="">
                 <!-- 五星级 -->
                </span></p>
             <div class="bigimg">
                <img src="../../assets/image/集中商业-转角透视.png" alt="">
                <img src="../../assets/image/旅游共享中心商业内街 (2).png" alt="">
             </div>
             <div class="smlallimg">
                  <img src="../../assets/image/中央花园鸟瞰图@2x.png" alt="">
                  <img src="../../assets/image/整体大鸟瞰图@2x.png" alt="">
                  <img src="../../assets/image/曝光度 1@2x.png" alt="">
             </div>
         </div>
         <div class="tabb">
                    <p class="more">更多酒店</p>
                    <table class="tabba" border="1" cellPadding="0" cellSpacing="0" bordercolor="#EBEBEB" style="text-align: center;">
                        <tr>
                            <td style="background: #0F6FB4; color: #fff;">
                                  {{$t('language.hotelname')}}
                                <!-- 酒店名称 -->
                            </td>
                            <td style="background: #197BC2; color:#fff;">
                              {{$t('language.starlevel')}}
                                <!-- 星级 -->
                            </td>
                            <td style="background: #2285CD; color:#fff;">
                                  {{$t('language.hoteladdress')}}
                                <!-- 酒店地址 -->
                                </td>
                            <td style="background: #3092D9; color:#fff;">
                              {{$t('language.Mileage')}}
                                <!-- 里程（公里） -->
                            </td>
                            <td style="background: #3D9EE4; color:#fff;">
                                 {{$t('language.Drive')}}
                                <!-- 车程（min) -->
                                </td>
                            <td style="background: #4AA8EC; color:#fff;">
                              {{$t('language.numberofrooms')}}
                                <!-- 房间总数 -->
                            </td>
                        </tr>
                        <tr v-for="(item,index) in tablelists" :key="index">
                            <td>
                                {{item.hotelName}}
                                <!-- 烟台金海岸希尔顿酒店 -->
                            </td>
                            <td>
                                 <img v-for="(items) in Number(item.star)" :key="items" src="../../assets/image/lALPDg7mRkdgwScSFA_20_18.png" alt="">
                            </td>
                            <td>
                                {{item.address}}
                                <!-- 开发区宁波路1号 -->
                            </td>
                            <td>
                                {{item.mileage}}
                            </td>
                            <td>
                                {{item.drive}}
                            </td>
                            <td>
                                {{item.homeNum}}
                            </td>
                        </tr>
                    </table>
         </div>
      </div>
</template>

<script>
export default {
    props:['tablelists'],
    data() {
        return {

        }
    },
    created() {
        
    },
    methods:{
        
    }
}
</script>

<style scoped>
    .tabb{
        text-align: center;
        margin-bottom: 0.2rem;
    }
    .phone img{
        width: 0.16rem;
        height: 0.16rem;
        vertical-align: middle;
        margin-right: 0.1rem;
    }
    .phone{
        width: 100%;
        display: flex;
        align-items: center;
    }
    .content{
        width: 100%;
    }
    
    .titleitem{
        width: 100%;
        height: 0.62rem;
        line-height: 0.62rem;
        text-align: center;
        font-size: 0.16rem;
    }
    .bigimg img{
        width: 100%;
        height: auto;
        display: block;
        margin: 0.04rem 0;
    }
    .contents{
        padding: 0 0.08rem;
        width: 100%;
        box-sizing: border-box;
    }
    .smlallimg img{
        width: 100%;
        height: auto;
        display: block;
        margin: 0.04rem 0;
    }
    .title{
        width: 100%;
        height: 0.54rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .title span:nth-of-type(1){
        font-size: 0.18rem;
    }
    .title span:nth-of-type(2){
        border-radius: 0.06rem;
        color: #fff;
        display: inline-block;
        height: 0.2rem;
        transform:scale(0.8);
        text-align: center;
        line-height: 0.2rem;
        margin-top: 0.05rem;
    }
    .tabba tr:nth-of-type(2n){
        background: #fff;
    }
    .tabba tr:nth-of-type(2n+1){
        background: #F5F5F5;
    }
</style>