<template>
  <div class="traffic">
      <div class="nav">
          <H5Carousel/>
      </div>
       <div class="content">
          <div class="navs">
              <van-collapse v-model="activeNames">
                <van-collapse-item :title="navtitle">
                    <div class="titleitem" v-for="(item,index) in nav" @click="gopage(index)" :key="index" :style="{background:item.background,color:item.color}">
                        {{show == true?item.title:item.titles}}
                    </div>
                </van-collapse-item>
              </van-collapse>
          </div>
         <div class="content" v-if="navtitle == '周边酒店' || navtitle == 'Surrounding hotels'">
            <Aroundthehotel :tablelists="tablelist"/>
            </div>
            <div class="content" v-else-if="navtitle == '停车信息' || navtitle == 'Parking information'">
            <Parkinginformation />
            </div>
            <div class="content" v-else-if="navtitle == '旅游景点' ||  navtitle == 'Scenic spot'">
            <Touristattractions />
            </div>
            <div class="content" v-else-if="navtitle == '交通指南' ||  navtitle == 'Traffic guide'">
            <Transportationguide />
            </div>
        </div>
      <div class="bot">
          <H5Bottom/>
      </div>
  </div>
</template>

<script>
import Aroundthehotel from "../../components/h5Peripheralservices/Aroundthehotel.vue";
import Parkinginformation from "../../components/h5Peripheralservices/Parkinginformation.vue";
import Touristattractions from "../../components/h5Peripheralservices/touristattractions.vue";
import Transportationguide from "../../components/h5Peripheralservices/transportationguide.vue";
import H5Carousel from '../../components/h5-carousel.vue'
import H5Bottom from '../../components/h5bottom.vue'
export default {
     components:{
        H5Carousel,
        H5Bottom,
        Aroundthehotel,
        Parkinginformation,
        Touristattractions,
        Transportationguide
    },
    data() {
        return {
            nav:[
                {title:"交通指南",background: "#197cc3",color: "#fff",titles:"Traffic guide"},
                {title:"停车信息",background: "#fff",color: "#000",titles:"Parking information"},
                {title:"周边酒店",background: "#fff",color: "#000",titles:"Surrounding hotels"},
                {title:"旅游景点",background: "#fff",color: "#000",titles:"Scenic spot"},
            ],
            activeNames: ['0'],
            navtitle:"",
            tablelist:[],
            show:""
        }
    },
    created() {
        if(this.$route.query.path == undefined || this.$route.query.path == '/'){
            if(localStorage.getItem('locale') == 'en-US'){
                 this.navtitle = 'Traffic guide'
            }else{
                 this.navtitle = '交通指南'
            }
         }else{
              let nav = this.nav
                let navlist =  nav.map((item)=>{
                    if(item.title == this.$route.query.path){
                        item.background = '#197cc3'
                        item.color = '#fff' 
                        if(localStorage.getItem('locale') == 'en-US'){
                            this.navtitle = item.titles
                        }else{
                            this.navtitle = item.title
                        }
                    }else{
                        item.background = '#fff'
                        item.color = '#000' 
                    }
                    
                    return item
                })
                if(localStorage.getItem('locale') == 'en-US'){
                    this.show = false
                }else{
                    this.show = true
                }
             this.nav = navlist
         }
        if(localStorage.getItem('locale') == 'en-US'){
            this.show = false
        }else{
            this.show = true
        }
         // 酒店信息
        this.hotelinformation()
    },
    methods:{
        // 酒店信息
        hotelinformation(){
            let data = {

            }
            this.$api.hotelinformation(data,res=>{
                if(res.data.code == 200){
                    this.tablelist = res.data.data
                }
            })
        },
        gopage(val){
            let nav = this.nav
           let navlist =  nav.map((item,index)=>{
                if(index == val){
                    item.background = '#197cc3'
                    item.color = '#fff' 
                }else{
                    item.background = '#fff'
                    item.color = '#000' 
                }
                return item
            })
            this.nav = navlist
           if(val == 0){
               if(localStorage.getItem('locale') == 'en-US'){
                   console.log("1");
                    this.navtitle = 'Traffic guide'
                }else{
                    console.log("2");
                    this.navtitle = '交通指南'
                }
               this.$router.replace({path:'/traffic',query:{path:`交通指南`}})
               this.activeNames = ["0"]
           }else if(val == 1){
               if(localStorage.getItem('locale') == 'en-US'){
                    this.navtitle = 'Parking information'
                }else{
                    this.navtitle = '停车信息'
                }
               this.$router.replace({path:'/traffic',query:{path:`停车信息`}})
               this.activeNames = ["1"]
           }else if(val == 2){
               if(localStorage.getItem('locale') == 'en-US'){
                    this.navtitle = 'Surrounding hotels'
                }else{
                    this.navtitle = '周边酒店'
                }
               this.$router.replace({path:'/traffic',query:{path:`周边酒店`}})
               this.activeNames = ["2"]
           }else if(val == 3){
               if(localStorage.getItem('locale') == 'en-US'){
                    this.navtitle = 'Scenic spot'
                }else{
                    this.navtitle = '旅游景点'
                }
               this.$router.replace({path:'/traffic',query:{path:`旅游景点`}})
               this.activeNames = ["3"]
           }
        }
    }
}
</script>

<style scoped>
    .trafficitem{
        padding: 0 0.08rem;
        box-sizing: border-box;
    }
    .imgs:nth-of-type(1){
        width: 0.76rem;
        height: 0.88rem;
        display: block;
        float: left;
        margin-right: 0.2rem;
    }
    .content{
        width: 100%;
    }
    .navs{
        width: 100%;
        height: auto;
    }
    .navs >>> .van-cell{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        border-bottom: 0.01rem solid #999;
        font-size: 0.16rem;
        height: 0.54rem;
    }
    .navs >>> .van-cell__title{
        flex: none;
    }
    .titleitem{
        width: 100%;
        height: 0.62rem;
        line-height: 0.62rem;
        text-align: center;
        font-size: 0.16rem;
    }
</style>