<template>
        <div class="content">
        <div class="imgtop">
          <p>
           <span>
              {{$t('language.PenglaiPavilionScenicArea')}}
              <!-- 蓬莱阁风景区 -->
            </span>
          </p>
          <p>
            <span v-html="$t('language.YantaiGoldenBeachSeasidePark')">
              <!-- 烟台金沙滩海滨公园 -->
            </span>
          </p>
          <p>
            <span>
              {{$t('language.YantaiMountainPark')}}
              <!-- 烟台山公园 -->
            </span>
          </p>
        </div>
        <div class="imgbot">
          <p class="left">
            <span>
              {{$t('language.PenglaiOceanPolarWorld')}}
              <!-- 蓬莱海洋极地世界 -->
            </span>
          </p>
          <div class="imgcent">
            <p>
             <span v-html="$t('language.YantaiyangmaIslandtouristresort')">
                <!-- 烟台养马岛旅游度假区 -->
              </span>
            </p>
            <p>
              <span v-html="$t('language.Yantaimagneticmountainhotspringtown')">
                <!-- 烟台磁山温泉小镇 -->
              </span>
            </p>
            <p>
              <span>
                {{$t('language.ChangyuWineCultureMuseum')}}
                <!-- 张裕酒文化博物馆 -->
              </span>
            </p>
          </div>
          <p class="right">
           <span>
              {{$t('language.LongIslandScenictouristarea')}}
              <!-- 长岛风景旅游区 -->
            </span>
          </p>
        </div>
      </div>
</template>

<script>
export default {
    data() {
        return {
           
        }
    },
    created() {

    },
    methods:{
        
    }
}
</script>

<style scoped>
    span{
    display: block;
    width: 2.75rem;
    height: 0.55rem;
    line-height: 0.55rem;
    text-align: center;
    color: #fff;
    font-size: 0.18rem;
    border-radius: 0.28rem;
    background: rgba(0, 0, 0, 0.7);
    margin: 0 auto;
    position: absolute;
    left: 0;right: 0;
    margin: auto;
    bottom: 0.2rem;
  }
  .content{
      padding: 0 0.08rem;
      width: 100%;
      height: auto;
      box-sizing: border-box;
  }
  .imgtop p{
    width: 100%;
    height: 2.5rem;
    margin: 0.08rem 0;
    position: relative;
  }
  .imgtop p:nth-of-type(1){
    background: url('../../assets/image/蓬莱阁@2x.png') no-repeat;
    background-size: 100% 100%;
  }
  .imgtop p:nth-of-type(2){
    background: url('../../assets/image/金沙滩@2x.png') no-repeat;
    background-size: 100% 100%;
  }
  .imgtop p:nth-last-of-type(1){
     margin-right: 0;
     background: url('../../assets/image/烟台山@2x.png') no-repeat;
    background-size: 100% 100%;
  }
  .left{
    width: 100%;
    height: 6.71rem;
    background: url('../../assets/image/海洋极地世界@2x.png') no-repeat;
    background-size: 100% 100%;
    margin: 0.08rem 0;
    position: relative;
  }
  .right{
    width: 100%;
    height: 6.71rem;
    background: url('../../assets/image/长岛@2x.png') no-repeat;
    background-size: 100% 100%;
    margin: 0.08rem 0;
    position: relative;
  }
  .imgcent p:nth-of-type(1){
    width: 100%;
    height: 2.61rem;
    background: url('../../assets/image/a1.png') no-repeat;
    background-size: 100% 100%;
    margin: 0.08rem 0;
    position: relative;
  }
  .imgcent p:nth-of-type(2){
    width: 100%;
    height: 2.61rem;
    background: url('../../assets/image/磁山@2x.png') no-repeat;
    background-size: 100% 100%;
    margin: 0.08rem 0;
    position: relative;
  }
  .imgcent p:nth-of-type(3){
    width: 100%;
    height: 2.61rem;
    background: url('../../assets/image/张裕@2x.png') no-repeat;
    background-size: 100% 100%;
    margin: 0.08rem 0;
    position: relative;
  }
</style>