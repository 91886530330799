<template>
      <!-- 交通指南 -->
      <div class="contents">
             <p class="ways">
                  {{$t('language.oftransportations')}}
                 <!-- 交通方式: -->
                 </p>
            <div class="trafficitem">
                <p class="way">
                     {{$t('language.expressway')}}
                    <!-- 高速公路 -->
                </p>
                <div>
                    <img class="imgs" src="../../assets/image/高速路出口@2x.png" alt="">
                    <p class="path">
                         {{$t('language.kilometersofexpressways')}}
                        <!-- 截止2020年，通车高速公路670公里，形成以沈海、荣乌、威青、烟海、龙青、蓬栖、文莱高速和国道204、206等高等级公路为主框架、四通八达的现代化公路网，对外出行十分便捷。 -->
                        </p>
                </div>
            </div>
            <div class="trafficitem">
                <p class="way">
                     {{$t('language.Railandhighspeedrail')}}
                    <!-- 铁路与高铁 -->
                    </p>
                <div>
                    <img class="imgs" style="width: 0.79rem;height: 0.79rem;display: block;" src="../../assets/image/高铁 动车@2x.png" alt="">
                    <p class="path">
                         {{$t('language.threelongitudinal')}}
                        <!-- 作为山东省“三纵三横”铁路网重要城市，青荣城际高速铁路1小时可达青岛，即将建成的环渤海城际高速铁路3小时可达北京，另通有烟台至大连轮渡铁路。 -->
                        </p>
                </div>
            </div>
            <div class="trafficitem">
                <p class="way">
                     {{$t('language.airport')}}
                    <!-- 机场 -->
                </p>
                <div>
                    <img class="imgs" src="../../assets/image/机场@2x.png" alt="">
                    <p class="path">
                         {{$t('language.YantaiPenglaiInternational')}}
                        <!-- 截止2020年底，烟台蓬莱国际机场与94个国内、国际及地区城市通航,开通航线169条, 形成“面向日韩、贯通南北、连接西北、辐射西南”的航线网络格局。蓬莱国际机场年旅客吞吐量达1000万人次，机场二期工程将于2022年竣工。 -->
                        </p>
                </div>
            </div>
            <div class="trafficitem">
                <p class="way">
                     {{$t('language.port')}}
                    <!-- 港口 -->
                </p>
                <div>
                    <img class="imgs" src="../../assets/image/bus.png" alt="">
                    <p class="path">
                         {{$t('language.Yantaiport')}}
                        <!-- 烟台港于1861年开港，是全国沿海25个重要枢纽港口和“一带一路“15个战略重要港口，货物吞吐量稳居全国前十。现有40万吨世界级矿石码头和30万吨世界级原油码头各1座，各类泊位242个，其中万吨级以上深水泊位74个。
烟台至大连轮渡实现6小时从山东半岛至辽东半岛，有效加深华北、华东与东北联系，2020年旅客吞吐量达580万人次。 -->
</p>
                </div>
            </div>
            
            <img class="map" src="../../assets/image/图层 74@2x.png" alt="">
         </div>
</template>

<script>
export default {

}
</script>

<style scoped>
    .ways{
        font-size: 0.2rem;
        font-family: Microsoft YaHei;
        font-weight: bold;
        margin: 0.12rem 0 0.12rem 0.08rem;
        color: #197CC3;
    }
    .imgs:nth-of-type(1){
        width: 0.76rem;
        height: 0.88rem;
        display: block;
        float: left;
        margin-right: 0.2rem;
    }
    .content{
        width: 100%;
    }
    .titleitem{
        width: 100%;
        height: 0.62rem;
        line-height: 0.62rem;
        text-align: center;
        font-size: 0.16rem;
    }
    .trafficitem{
        width: 100%;
        padding: 0 0.08rem;
        box-sizing: border-box;
    }
    .way{
        font-size: 0.2rem;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        text-align: center;
        margin: 0.12rem 0;
    }
    .path{
        font-size: 0.14rem;
        line-height: 0.3rem;
    }
    .map{
        width: calc(100% - 0.16rem);
        margin: 0 auto;
        display: block;
        margin-bottom: 0.08rem;
        height: auto;
    }
</style>